<template>
  <div class="manage-project-marketing-staff-add">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h2 class="mb-5">Add Developer User</h2>

          <fd-form-section title="Account Credential">
            <fd-input
              v-model="projectMarketingStaff.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <fd-input
              v-model="projectMarketingStaff.password"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              :validators="[validator.required, validator.password]"
            >
              <template #append-icon>
                <span
                  class="cursor-pointer"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-eye': !showPassword,
                      'fa-eye-slash': showPassword
                    }"
                  ></i>
                </span>
              </template>
            </fd-input>
          </fd-form-section>

          <fd-form-section title="Company Information">
            <div class="row col-12">
              <display-data
                class="px-1 mb-2"
                label="Developer Company"
                :isLoading="isDeveloperLoading"
                :content="developer.name"
              >
              </display-data>
              <div class="col-12">
                <fd-select
                  v-model="projectMarketingStaff.roleId"
                  class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                  label="Role"
                  :options="roleOptions"
                  selectText="Choose a role"
                  :disabled="!isRoleSelectable"
                  :isLoading="isRoleLoading"
                  :validators="[validator.required]"
                >
                </fd-select>
              </div>
              <div class="col-12 px-1">
                <banner
                  v-if="userCount !== null && userCount < 1"
                  class="bg-secondary fg-white md-col-6 mb-1"
                  :dismissable="false"
                >
                  This is the first user to be created for this company, the
                  role has to be set as Owner, which is the highest hierarchy
                  user.
                </banner>
              </div>
            </div>
          </fd-form-section>

          <fd-form-section title="Personal Information">
            <fd-input
              v-model="projectMarketingStaff.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="projectMarketingStaff.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
          </fd-form-section>

          <div class="text-right p-2">
            <fd-button
              type="submit"
              class="main"
              :disabled="
                isRoleLoading || isUserCountLoading || isDeveloperLoading
              "
              >Create</fd-button
            >
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";

// Validator
import {
  required,
  password,
  email,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// API
import {
  projectMarketingStaff as projectMStaffAPI,
  developer as projectDeveloperAPI
} from "@/api";
// Model
import { ProjectMarketingStaffModel } from "@/models";
// Composable
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

const OWNER_ROLE_ID = 1;

export default {
  setup(props, context) {
    let toDeveloperUsers = () => {
      context.root.$router.push({
        name: "ManageDeveloperUsers",
        params: {
          id: this.$route.params.developerId
        }
      });
    };
    const submitHandlerOptions = {
      role: "developer user",
      successCallback: toDeveloperUsers
    };
    const { handleCreateSuccess, handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleCreateSuccess,
      handleSubmitError
    };
  },
  name: "AddProjectMarketingStaff",
  components: {
    TelInput,
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Banner: () => import("@/components/GlobalComponents/Banner")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      roleOptions: [],

      isRoleLoading: false,
      isUserCountLoading: false,
      isDeveloperLoading: false,

      userCount: null,
      developer: {
        name: ""
      },

      phoneInput: "",
      projectMarketingStaff: {
        email: "",
        password: "",

        name: "",
        phoneNo: {},
        developerId: "",
        roleId: ""
      },

      validator: {
        required: required,
        password: password,
        email: email,
        phone: phone
      },

      showPassword: false
    };
  },
  computed: {
    isRoleSelectable() {
      return !this.isRoleLoading && this.userCount > 0;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([
        this.getDeveloper(),
        this.getDeveloperUserCount(),
        this.getRoleOptions()
      ]);
    },
    async getDeveloper() {
      try {
        this.isDeveloperLoading = true;
        let developerId = this.$route.params.developerId;
        let data = await projectDeveloperAPI.getDeveloper(developerId);
        this.developer = this._.cloneDeep(data);
        this.projectMarketingStaff.developerId = developerId;
        this.isDeveloperLoading = false;
      } catch (error) {
        this.isDeveloperLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch developer's info. Please try again later."
        });
      }
    },
    async getDeveloperUserCount() {
      this.isUserCountLoading = true;
      let res = await projectMStaffAPI.getProjectMarketingStaffs({
        queries: { "developer:id": this.$route.params.developerId }
      });
      this.isUserCountLoading = false;
      this.userCount = this._.cloneDeep(res.meta.pagination.total);
      if (this.userCount < 1) {
        this.projectMarketingStaff.roleId = OWNER_ROLE_ID;
      }
    },
    async getRoleOptions() {
      try {
        this.isRoleLoading = true;
        let developerId = this.$route.params.developerId;
        let data = await projectDeveloperAPI.getDeveloperRoles(developerId);
        this.roleOptions = this._.cloneDeep(data.data);
        this.isRoleLoading = false;
      } catch (error) {
        this.isRoleLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get developer roles. Please try again later."
        });
      }
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await projectMStaffAPI.createProjectMarketingStaff(
          ProjectMarketingStaffModel.postMarketingStaffPayload(
            this.projectMarketingStaff
          )
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Developer user is created successfully."
        });
        this.$router.push({
          name: "ManageDeveloperUsers",
          params: { developerId: this.$route.params.developerId }
        });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create developer user. Please try again later."
        });
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.manage-project-marketing-staff-add {
  form {
    @extend %formDesign;
  }
}
</style>
